import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'

const CityInput = styled.input`
  font-family: 'Source Sans Pro';
  padding: 12px 16px;
  border-radius: 5px;
  border: solid 1px ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  font-size: 16px;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  
  &::placeholder {
    opacity: 0.5;
    color: ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  }

  &:focus {
    &::placeholder {
      color: ${({ theme }) => get(theme, 'blue', '#fff')};
    }
  }

  ${media.lessThan('sm')`
    margin-top: 8px;
    font-size: 16px;
    padding: 10px 12px;
    line-height: 1.3;
  `}
`

export default CityInput
