import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import PTZTVA from '../components/organisms/PTZTVA'
import PTZIntro from '../components/organisms/PTZIntro'
import PTZDetails from '../components/organisms/PTZDetails'
import PTZFolhomee from '../components/organisms/PTZFolhomee'
import SiteSection from '../components/atoms/SiteSection/'
import PTZResources from '../components/organisms/PTZResources'
import PTZConditions from '../components/organisms/PTZConditions'
import PTZTopSection from '../components/organisms/PTZTopSection'
import PTZResourcesConditions from '../components/organisms/PTZResourcesConditions'

const TITLE = 'Tout ce qu\'il faut savoir sur le PTZ, Prêt à Taux Zéro'
const DESCRIPTION = 'Que faut-il savoir sur le PTZ ? Comment estimer le montant de mon prêt à taux zéro ? Folhomee répond à vos questions sur ce prêt immo avantageux.'

const LastSiteSection = styled(SiteSection)`
  margin-bottom: 0;
`
const PretTauxZero = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <PTZTopSection />
    <SiteSection id='ptz-intro' $first={true}>
      <PTZIntro />
    </SiteSection>
    <SiteSection id='ptz-details'>
      <PTZDetails />
    </SiteSection>
    <SiteSection id='ptz-conditions'>
      <PTZConditions />
    </SiteSection>
    <SiteSection id='ptz-resources'>
      <PTZResources />
    </SiteSection>
    <SiteSection id='ptz-tva'>
      <PTZTVA />
    </SiteSection>
    <SiteSection id='ptz-resources-conditions'>
      <PTZResourcesConditions />
    </SiteSection>
    <LastSiteSection id='ptz-folhomee' $last={true}>
      <PTZFolhomee />
    </LastSiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

PretTauxZero.propTypes = {
  location: PropTypes.object.isRequired
}

export default PretTauxZero
