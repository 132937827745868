import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PTZHero from '../../molecules/PTZHero'
import TopSection from '../../molecules/TopSection'
import CalculatorImage from '../../molecules/CalculatorImage'

const StyledCalculatorImage = styled(CalculatorImage)`
  z-index: -1;
  display: none;
  

  ${media.greaterThan('xl')`
    display: block;
    height: 600px; 
    top: 210px;
  `}
`

const PTZTopSection = () => (
  <TopSection component={StyledCalculatorImage}>
    <PTZHero />
  </TopSection>
)

export default PTZTopSection
