import React, { useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import theme from '../../../config/theme'

const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const CheckContainer = styled.div`
  min-width: 20px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
  background-color: ${({ theme }) => get(theme, 'white', '#fff')};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`

const Text = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 1.42;
  color: ${({ theme, error }) => get(theme, error ? 'brightOrange' : 'blue', '#fff')};
`

const Check = ({ checked, onClick, error }) => (
  <CheckContainer onClick={onClick} error={error}>
    {checked && <FontAwesomeIcon icon='check' color={get(theme, error ? 'brightOrange' : 'blue')} />}
  </CheckContainer>
)

Check.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

const CheckBox = ({ values, name, setFieldValue, text, error }) => {
  const onClick = useCallback(() => setFieldValue(name, !get(values, name)), [name, values, setFieldValue])

  return (
    <Container onClick={onClick}>
      <Check
        error={error}
        onClick={onClick}
        checked={get(values, name)} />
      <Text error={error}>{text}</Text>
    </Container>
  )
}

CheckBox.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired
}

export default CheckBox
