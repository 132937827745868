import React, { useState, useCallback } from 'react'
import qs from 'qs'
import axios from 'axios'
import publicIp from 'public-ip'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import { Spinner } from '@styled-icons/fa-solid'
import { useFormik } from 'formik'
import { StaticImage } from 'gatsby-plugin-image'
import { useSwipeable } from 'react-swipeable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { get, map, replace, isEqual, isEmpty, reduce } from 'lodash'
import styled, { keyframes } from 'styled-components'
import * as Yup from 'yup'

import Toast from '../../atoms/Toast'
import theme from '../../../config/theme'
import media from '../../../utils/media'
import CheckBox from '../../atoms/Checkbox'
import FormInput from '../../atoms/CityInput'
import SelectField from '../../atoms/SelectField'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const getClientIp = async () => await publicIp.v4({
  fallbackUrls: ['https://ifconfig.co/ip']
})

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinningIcon = styled(Spinner)`
  margin-right: 0.25rem;
  animation: 2s linear ${spin} infinite;
`

const BaseContainer = styled.div`
  ${media.lessThan('xl')`
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
  `}
`

const IconContainer = styled.div`
  display: none;

  ${media.lessThan('xl')`
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`

const Icon = styled(FontAwesomeIcon)`
  font-size: 32px;
  transition: transform 330ms ease-in-out;
  ${({ show }) => show ? 'transform: rotate(180deg);' : ''}
`

const Container = styled.div`
  width: 300px;
  height: auto;
  display: flex;
  position: sticky;
  flex-direction: column;
  padding: 32px 24px;
  background-color: ${({ theme }) => get(theme, 'paleBlue', '#fff')};
  margin-left: 35px;
  top: 4rem;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  
  ${media.lessThan('xl')`
    width: 100%;
    border-radius: 20px 20px 0 0;
    margin-left: 0;
    padding: 16px 0;
    border: 0.1px solid rgba(0, 73, 238, 0.15);
  `}

  ${media.lessThan('md')`
    padding: 16px 0 24px;
  `}

  ${media.lessThan('sm')`
    padding: 16px;
  `}
`

const TitleContainer = styled.div`
  display: flex;
  border-bottom: 2px solid ${({ theme }) => get(theme, 'lightOrange', '#fff')};
  align-items: center;
  padding-bottom: 16px;

  ${media.lessThan('xl')`
    border-bottom: none;
    padding-bottom: 0;
  `}
`

const ImageContainer = styled.div`
  width: 80px;
  height: auto;
  margin-right: 16px;

  ${media.lessThan('md')`
    width: 60px;
  `}
`

const Title = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  padding-top: 0;

  ${media.lessThan('md')`
    font-size: 20px;
    padding-top: 8px;
  `}

  ${media.lessThan('xl')`
    padding-top: 16px;
  `}
`

const DetailContainer = styled.div`
  ${media.greaterThan('md')`
    width: 460px;
  `}

  ${media.lessThan('xl')`
    overflow-y: hidden;
    transition: all 500ms ease-in-out;
    max-height: ${({ show }) => show ? '90vh' : '0'};
  `}

  ${media.greaterThan('xl')`
    width: 250px;
    margin: 24px auto;
  `}
`

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 24px auto;
`

const Error = styled.span`
  font-family: 'Source Sans Pro';
  color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
  font-size: 14px;
  margin-top: 8px;
`

const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  border: none;
  width: 100%;
  border-radius: 25px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
  color: ${({ theme }) => get(theme, 'white', '#fff')};

  ${media.lessThan('sm')`
    font-size: 14px;
  `}
`

const StyledToast = styled(Toast)`
  width: 100%;
  position: relative;
  top: 100;
`
const StyledIconContainer = styled(IconContainer)`
  height: 20px;
  position: absolute;
  top: 24px;

  ${media.lessThan('md')`
  top: 16px;
  `}

  ${media.lessThan('sm')`
  top: 16px;
  `}
`

const Form = ({ form, data, inputs, submit }) => {
  const [show, updateShow] = useState(false)
  const [loading, updateLoading] = useState(false)

  const formSchema = Yup.object().shape(reduce(inputs, (acc, { name, constraint }) => ({
    ...acc,
    [name]: constraint
  }), {}))

  const {
    values, handleSubmit, handleChange, resetForm, setFieldValue, errors, touched, handleBlur
  } = useFormik({
    enableReinitialize: true,
    initialValues: reduce(inputs, (acc, { name, initial }) => ({
      ...acc,
      [name]: initial
    }), {}),
    validationSchema: formSchema,
    onSubmit: async values => {
      if (loading) {
        return
      }

      try {
        updateLoading(true)
        const eventId = nanoid()
        const time = Math.floor(Date.now() / 1000)
        const userAgent = navigator.userAgent
        const userIp = await getClientIp()
        await axios.post(submit, JSON.stringify({
          ...data,
          type: form,
          form,
          time,
          userIp,
          eventId,
          userAgent,
          eventSourceUrl: get(location, 'href'),
          ...values
        }))
        resetForm()
        updateShow(true)
        trackCustomEvent({
          category: 'send button',
          action: 'click',
          label: 'envoie formulaire'
        })

        if (typeof window.fbq === 'function' &&
          !!get(qs.parse(replace(get(location, 'search'), '?', '')), 'facebook')) {
          window.fbq('track', 'CompleteRegistration', {
            content_name: values.email,
            event_id: eventId,
            event_source_url: get(location, 'href')
          }, {
            eventID: eventId
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        updateLoading(false)
      }
    }
  })

  return (
    <FormContainer onSubmit={handleSubmit}>
      {map(inputs, ({ type, name, placeholder, options }) => {
        if (isEqual(type, 'accept')) {
          return (
            <React.Fragment key={name}>
              <CheckBox
                text={placeholder}
                name={name}
                error={get(touched, name) && get(errors, name)}
                values={values}
                setFieldValue={setFieldValue} />
            </React.Fragment>
          )
        }

        if (isEqual(type, 'select')) {
          return (
            <React.Fragment key={name}>
              <SelectField
                name={name}
                value={get(values, name)}
                error={get(touched, name) && get(errors, name)}
                onBlur={handleBlur}
                options={options}
                onChange={setFieldValue}
                placeholder={placeholder}
                handleChange={handleChange} />
              {get(touched, name) && get(errors, name) &&
                <Error>{get(errors, name)}</Error>}
            </React.Fragment>
          )
        }

        return (
          <React.Fragment key={name}>
            <ContainerFormInput>
              <FormInput
                type={type}
                name={name}
                error={get(touched, name) && get(errors, name)}
                value={get(values, name)}
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={placeholder} />
              {get(touched, name) && get(errors, name) &&
                <Error>{get(errors, name)}</Error>}
            </ContainerFormInput>
          </React.Fragment>
        )
      })}
      <StyledToast
        show={show}
        onClose={() => updateShow(false)}>
        Vos informations viennent d&apos;être transmises à l&apos;équipe commerciale. <strong>Vous allez être contacté très rapidement !</strong>
      </StyledToast>
      <SubmitButton type='submit' disabled={loading}>
        {loading &&
          <SpinningIcon
            size={20}
            role='status' />}
        Être rappelé·e
      </SubmitButton>
    </FormContainer>
  )
}

const ContainerFormInput = styled.div`
  display: flex;
  flex-direction: column;
`

Form.propTypes = {
  form: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  program: PropTypes.object.isRequired
}

const DropForm = ({ form, submit, inputs, data, subtitle }) => {
  const [show, updateShow] = useState(false)

  const toggleShow = useCallback(() => updateShow(!show), [updateShow, show])
  const handlers = useSwipeable({
    onSwipeUp: () => updateShow(true),
    onSwipeDown: () => updateShow(false),
    preventDefaultTouchmoveEvent: true
  })

  return (
    <BaseContainer>
      <Container>
        <StyledIconContainer
          onClick={toggleShow}
          {...handlers}>
          <Icon show={show} icon='angle-up' color={get(theme, 'blue')} />
        </StyledIconContainer>
        <TitleContainer onClick={toggleShow} {...handlers}>
          <ImageContainer>
            <StaticImage src='../../../images/humantalk.png' alt='drop-form-image' />
          </ImageContainer>
          <Title>
            Parlons de votre projet
          </Title>
        </TitleContainer>
        <DetailContainer show={show}>
          {!isEmpty(subtitle) && <HomeSectionDescription>
            {subtitle}
          </HomeSectionDescription>}
          <Form
            data={data}
            form={form}
            submit={submit}
            inputs={inputs} />
        </DetailContainer>
      </Container>
    </BaseContainer>
  )
}

DropForm.propTypes = {
  form: PropTypes.string,
  data: PropTypes.object,
  submit: PropTypes.string.isRequired,
  inputs: PropTypes.array.isRequired
}

DropForm.defaultProps = {
  form: 'recherche drop form',
  data: {}
}

export default DropForm
