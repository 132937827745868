import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import PhonePTZ from '../../molecules/PTZPhone'
import ImageSection from '../../molecules/ImageSection'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledButton = styled(LaunchButton)`
  margin-top: 15px;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`
const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  > span {
    display: block;
    margin: 16px 0;
    font-weight: 600;
    color: ${({ theme }) => get(theme, 'blue')};
  }
  
`
const transformScale = {
  xs: {
    transform: 'scale(1.6)'
  },
  md: {
    transform: 'scale(1.2)'
  },
  lg: {
    transform: 'scale(1.5)'
  },
  xl: {
    transform: 'scale(1.25)'
  }
}

const size = {
  xxl: {
    maxWidth: '830px'
  }
}

const PTZFolhomee = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "phone-ptz.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      fixed={true}
      right={false}
      imageData={placeholderImage}
      backgroundColor='paleBlue'
      transformScale={transformScale}>
      <HomeSectionTitle>
        Trouvez le bien immobilier neuf que vous souhaitez acheter avec Folhomee.
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Quelle que soit votre zone géographique de prédilection, cumulez jusqu’à 25 critères de recherche !
      </HomeSectionSubtitle>
      <StyledHomeSectionDescription>
      Ainsi, Folhomee vous assure de ne recevoir que des offres qui correspondent vraiment à vos attentes. Par ailleurs, s’inscrire chez Folhomee, c’est recevoir les annonces en temps réel, dès leur publication.
      <span> Comme toute autre demande de prêt immobilier, la demande de PTZ se fait auprès d’une banque.
      </span> Nous vous conseillons de rassembler tous les documents et pièces justificatives nécessaires avant de vous rendre à votre rendez-vous. En effet, un dossier complet et bien organisé permet de maximiser vos chances d’obtenir un prêt à taux zéro, ou tout autre type de prêt immobilier d’ailleurs.
      </StyledHomeSectionDescription>
      <HomeSectionSubtitle>
        Faites-vous accompagner par Folhomee et ses experts. De la recherche de votre bien immobilier à son ameublement, en passant par son financement, nous vous guidons pas à pas.
      </HomeSectionSubtitle>
      <ButtonContainer>
        <StyledButton
          title='Lancer ma recherche'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default PTZFolhomee
