import React from 'react'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from 'gbimage-bridge'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(BackgroundImage)`
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  ${media.lessThan('xl')`
    display: none;
  `}
`

const StyledContainer = styled.div`
  ${media.greaterThan('xl')`
    display: none;
  `}
`

const Content = styled.div`
  margin: 0px 24px;
  max-width: 800px;

  ${media.greaterThan('sm')`
    margin: 0px 40px;
  `}

  ${media.greaterThan('md')`
    margin: 0px 72px;
  `}

  ${media.greaterThan('lg')`
    margin: 0px auto;
  `}

  ${media.greaterThan('xl')`
    max-width: 570px;
    margin-left: 50%;
  `}
`

const PTZIntro = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "intro-ptz.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const image = getImage(placeholderImage)
  const bgImage = convertToBgImage(image)

  return (
    <>
      <StyledImageSection
        alt='aide achat immobilier'
        Tag='div'
        {...bgImage}
        preserveStackingContext>
        <Content>
          <HomeSectionTitle tag='h2'>
            Qu’est-ce que le PTZ&nbsp;?
          </HomeSectionTitle>
          <HomeSectionSubtitle>
            Souvent connu de nom uniquement, le prêt à taux zéro mérite pourtant d’être étudié en détail.
          </HomeSectionSubtitle>
          <HomeSectionDescription>
            Il peut véritablement changer la donne de votre investissement immobilier, que vous décidiez d’acheter une maison ou un appartement. Les personnes qui se demandent comment marche le PTZ trouveront, ici, toutes les informations qu’elles cherchent.
          </HomeSectionDescription>
        </Content>
      </StyledImageSection>
      <StyledContainer>
        <Content>
          <HomeSectionTitle tag='h2'>
            Qu’est-ce que le PTZ&nbsp;?
          </HomeSectionTitle>
          <HomeSectionSubtitle>
            Souvent connu de nom uniquement, le prêt à taux zéro mérite pourtant d’être étudié en détail.
          </HomeSectionSubtitle>
          <HomeSectionDescription>
            Il peut véritablement changer la donne de votre investissement immobilier, que vous décidiez d’acheter une maison ou un appartement. Les personnes qui se demandent comment marche le PTZ trouveront, ici, toutes les informations qu’elles cherchent.
          </HomeSectionDescription>
        </Content>
        <GatsbyImage
          image={image}
          alt='Folhomee Intro'
          fadeIn={false} 
          loading='eager' />
      </StyledContainer>
    </>
  )
}

export default PTZIntro
