import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import media from '../../../utils/media'

import StyledList from '../../atoms/StyledList'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => get(theme, 'paleGrey')};
  padding: 56px 24px;

  ${media.greaterThan('sm')`
  padding: 72px 40px;
  `}

  ${media.greaterThan('md')`
  padding: 72px;
  `}
`

const TextContainer = styled.div`
  max-width: 830px;
  display: flex;
  flex-direction: column;
`

const Link = styled.a`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'brightOrange')};
  text-decoration: none;
  margin-top: 8px;

  ${media.lessThan('sm')`
    font-size: 15px;
  `}
`

const PTZTVA = () => (
  <Container>
    <TextContainer>
      <HomeSectionTitle tag='h3'>
        Cumuler PTZ et TVA réduite, est-ce possible&nbsp;?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La TVA réduite est un des avantages lorsqu’on réalise un investissement dans l’immobilier neuf. Tout comme pour obtenir un PTZ, il faut respecter certaines conditions pour prétendre à la TVA réduite
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Il apparaît que, si vous répondez aux critères d’éligibilité des deux dispositifs, il est possible de cumuler PTZ et TVA réduite. À savoir que la TVA réduite est un taux de 5,5% au lieu de 20%, de quoi réaliser de belles économies.
      </HomeSectionDescription>
      <HomeSectionDescription>
        Les conditions auxquelles l’acquéreur doit répondre pour bénéficier de la TVA réduite sont les suivantes&nbsp;:
      </HomeSectionDescription>
      <StyledList>
        <li>
          le bien visé doit se trouver dans une zone ANRU, ou dans les 300 mètres alentours&nbsp;;
        </li>
        <li>
          il doit s’agir d’un investissement immobilier dans le neuf pour résidence principale&nbsp;;
        </li>
        <li>
          les revenus fiscaux de l’acquéreur ne doivent pas dépasser un certain seuil.
        </li>
      </StyledList>
      <HomeSectionDescription>
        Une zone ANRU (Agence Nationale de Rénovation Urbaine) décrit une zone géographique nécessitant un besoin de revitalisation. L’ANRU et les collectivités territoriales agissent ensemble pour définir ce type de zone géographique.
      </HomeSectionDescription>
      <Link href='https://www.anru.fr/programmes/carte' target='_blank' rel='nofollow'>
        Consulter la liste complète des zones ANRU.
      </Link>
    </TextContainer>
  </Container>
)

export default PTZTVA
