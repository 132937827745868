import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoNew from '../../../assets/new.inline.svg'
import LineBreak from '../../atoms/LineBreak'
import PictoPrimo from '../../../assets/primo.inline.svg'
import StyledList from '../../atoms/StyledList'
import PTZWithForm from '../../molecules/PTZWithForm'
import PictoCardboard from '../../../assets/cardboard.inline.svg'
import PictoPiggyLarge from '../../../assets/piggy-large.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoNew,
  description: 'Acheter un bien issu <strong>d’un programme immobilier neuf</strong>'
}, {
  icon: PictoCardboard,
  description: 'Prouver qu’il s’agira de leur <strong>résidence principale</strong>'
}, {
  icon: PictoPrimo,
  description: '<strong>Être primo accédants,</strong> donc ils ne doivent pas déjà être propriétaires.'
}]

const DETAILS_COL = [{
  icon: PictoPiggyLarge,
  title: 'D’un point de vue plus concret, le prêt à taux zéro peut permettre d’économiser jusqu’à&nbsp;25&nbsp;000 euros d’intérêts.',
}]

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin: 24px 0 160px;
  font-weight: 600;

  ${media.lessThan('sm')`
  margin: 24px 0 120px;
  `}
`

const OtherHomeSectionDescription = styled(HomeSectionDescription)`
    margin-top: 32px;
`

const PTZDetails = () => (
  <PTZWithForm>
    <HomeSectionTitle tag='h3'>
      Ce qu’il faut savoir sur le prêt à taux zéro
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Tout d’abord, comme son nom l’indique, le prêt à taux zéro est un prêt <LineBreak />immobilier dont le taux de remboursement est de&nbsp;0%.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
        En d’autres termes, l’investisseur ne paie pas d’intérêts sur une partie de son crédit immo. Attention cependant, le PTZ ne peut pas financer la totalité d’un emprunt immobilier. En effet, la somme maximale prêtée à taux zéro correspond à 40% du prix d’achat du bien.
      <PictoColContainer details={DETAILS_COL}/>
      <HomeSectionDescription>
        Bien sûr, il y a des critères d’éligibilité à respecter pour pouvoir prétendre au prêt à taux zéro. Les deux principaux critères sont le <strong>caractère neuf</strong> du bien concerné par l’achat et le <strong>type de résidence</strong> dont il est question. Dans les faits, il n’est pas possible d’obtenir un PTZ pour l’achat d’une résidence secondaire. 
      <StyledHomeSectionDescription>
        En revanche, en ce qui concerne l’emplacement géographique du bien, il est possible de demander un PTZ quelle que soit la ville.
      </StyledHomeSectionDescription>
      </HomeSectionDescription>
    </HomeSectionDescription>
    <HomeSectionTitle tag='h3'>
      Les situations dans lesquelles le PTZ peut être appliqué
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Comme évoqué précédemment, certaines situations ne peuvent pas se traduire par <LineBreak />l’obtention d’un PTZ.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Pour pouvoir bénéficier de ce type de prêt immobilier, les investisseurs doivent&nbsp;:
    </HomeSectionDescription>
    <PictoLineContainer details={DETAILS_LINE}/>
    <HomeSectionDescription>
      <strong>À savoir que tous les types de biens immobiliers neufs sont concernés par le PTZ.</strong> Qu’il s’agisse d’un appartement, d’un studio ou même d’une villa, du moment qu’il s’agit d’un bien neuf, il sera envisageable de profiter du prêt à taux zéro. 
    </HomeSectionDescription>
    <OtherHomeSectionDescription>
      Bien entendu, il existe des exceptions pour le PTZ. Par exemple, il est possible de profiter d’un <strong>prêt à taux zéro pour un logement ancien avec travaux si&nbsp;:</strong>
    </OtherHomeSectionDescription>
    <StyledList>
      <li>
        les investisseurs sont bénéficiaires de l’AAH (Allocation Adulte Handicapé) ou de l’AEEH (Allocation d’Éducation de l’Enfant Handicapé)&nbsp;;
      </li>
      <li>
        les investisseurs ont subi une catastrophe naturelle ou technologique rendant leur résidence principale totalement inhabitable.
      </li>
    </StyledList>
  </PTZWithForm>
)

export default PTZDetails
