import React from 'react'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { map, isEqual, get, range } from 'lodash'

import media from '../../../utils/media'
import LineBreak from '../../atoms/LineBreak'
import StyledList from '../../atoms/StyledList'
import PTZWithForm from '../../molecules/PTZWithForm'
import LaunchButton from '../../atoms/LaunchButton'
import ResourcesTable from '../../molecules/ResourcesTable'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ZONES = [{
  color: '#487cf3',
  title: 'Zone A :',
  cities: 'Paris, régions proches de la banlieue parisienne, Côte d’Azur, Genevois, Ajaccio, Annemasse, Fréjus, Lille, Lyon, Marseille - Aix en Provence, Meaux, Menton, Montpellier, Nice'
}, {
  color: '#9db9f9',
  title: 'Zone B1 :',
  cities: 'Autres agglomérations de plus de 250K habitants, la grande couronne parisienne, Annecy, Bayonne, Caen, Chambéry, Cluses, Dijon, La Rochelle, Le Havre, Saint-Malo, DOM, Corse'
}, {
  color: '#dae5fd',
  title: 'Zone B2 :',
  cities: 'autres communes de plus de 50K habitants'
}, {
  color: '#fff',
  title: 'Zone C :',
  cities: 'tout le reste du territoire français'
}]

const PERSONS_RESOURCES = map(range(1, 9), idx => {
  if (isEqual(idx, 1)) {
    return '1 personne'
  }

  if (isEqual(idx, 8)) {
    return '8 personnes et plus'
  }

  return `${idx} personnes`
})

const ZONES_RESOURCES = [{
  title: 'Zone ABis et A',
  lines: ['37 000 €', '51 800 €', '62 900 €', '74 000 €', '85 100 €', '96 200 €', '107 300 €', '118 400 €']
}, {
  title: 'Zone B1',
  lines: ['30 000 €', '42 000 €', '51 000 €', '60 000 €', '69 000 €', '78 000 €', '87 000 €', '96 000 €']
}, {
  title: 'Zone B2',
  lines: ['27 000 €', '37 800 €', '45 900 €', '54 000 €', '62 100 €', '70 200 €', '78 300 €', '86 400 €']
}, {
  title: 'Zone C',
  lines: ['24 000 €', '33 600 €', '40 800 €', '48 000 €', '55 200 €', '62 400 €', '69 600 €', '76 800 €']
}]

const StyledButton = styled(LaunchButton)`
  margin: 16px auto 0;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const ResourcesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  ${media.lessThan('sm')`
    flex-direction: column;
  `}
`

const MapDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  justify-content: center;
  gap: 16px;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.lessThan('sm')`
    padding: 24px;
  `}
`

const DetailContainer = styled.div`
  display: flex;
`

const DetailColor = styled.div`
  width: 29px;
  height: 29px;
  margin-right: 16px;
  border-radius: 5px;
  background-color: ${({ color }) => color};
  ${({ color, theme }) => isEqual(color, '#fff') ? `border: 1px solid ${get(theme, 'blue')}` : ''}
`

const DetailTitle = styled(HomeSectionSubtitle)`
  margin: 0;
`

const DetailText = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledHomeSectionTitle = styled(HomeSectionTitle)`
  margin-top: 120px;

  ${media.greaterThan('sm')`
  margin-top: 160px;
  `}
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  & > span {
    margin: 16px 0 16px;
    color:  ${({ theme }) => get(theme, 'blue')};
    display: block;
    
    & strong {
      font-weight: 600;
    }
  }
`

const PTZResources = () => (
  <PTZWithForm>
    <div>
    <HomeSectionTitle tag='h3'>
      Conditions de ressources et PTZ
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Toute personne ne peut pas prétendre au PTZ. En effet, il est de mise de respecter certaines conditions de ressources pour obtenir un emprunt à taux zéro.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Afin de vérifier <strong>votre éligibilité au PTZ</strong>, il est possible de vous référer au tableau suivant. Celui-ci présente toutes les conditions de ressources concernant le prêt à taux zéro.
    </HomeSectionDescription>
    <ResourcesContainer>
      <ResourcesTable
        top={12}
        zones={ZONES_RESOURCES}
        title='Nombre de personnes au sein du foyer'
        persons={PERSONS_RESOURCES}
        subtitle='Plafonds de ressources pour prétendre au PTZ (par an et en euros)' />
    </ResourcesContainer>
    <HomeSectionDescription>
      Attention, il est important de garder à l’esprit que le PTZ est un emprunt immobilier engageant envers une banque. À ce titre, même si vous répondez à tous les critères d’éligibilité du PTZ, il est possible que celui-ci vous soit refusé. D’une façon générale, les deux motifs justifiant un refus de PTZ sont&nbsp;:
    </HomeSectionDescription>
    <StyledList>
      <li>
        vos revenus sont considérés comme trop faibles pour couvrir les mensualités du prêt&nbsp;;
      </li>
      <li>
        vos garanties financières ne sont pas jugées assez sérieuses pour la banque.
      </li>
    </StyledList>
    <StyledHomeSectionTitle tag='h3'>
      À quoi correspondent les zones du PTZ&nbsp;?
    </StyledHomeSectionTitle>
    <HomeSectionSubtitle>
      Les investisseurs qui souhaitent profiter du PTZ le savent : il existe des zones correspondant à ce type d’emprunt bancaire.
    </HomeSectionSubtitle>
    <StyledHomeSectionDescription>
      <span>En fait, il est possible d’avoir un prêt à taux zéro quelle que soit la ville dans laquelle vous achetez, mais le montant varie selon les zones. Les zones du PTZ, comment ça marche&nbsp;? Le tableau qui suit vous permet de comprendre le fonctionnement géographique de ce type de prêt immobilier.</span>
      <span>Il existe <strong>4 zones géographiques</strong> pour le prêt à taux zéro, vous les retrouverez en détails dans le tableau suivant.</span>
    </StyledHomeSectionDescription>
    <ResourcesContainer>
      <MapDetailsContainer>
        {map(ZONES, ({ color, title, cities }) => (
          <DetailContainer>
            <div>
              <DetailColor color={color} />
            </div>
            <DetailText>
              <DetailTitle>
                {title}
              </DetailTitle>
              <HomeSectionDescription>
                {cities}
              </HomeSectionDescription>
            </DetailText>
          </DetailContainer>
        ))}
      </MapDetailsContainer>
      <ImageContainer>
        <StaticImage
          src='../../../images/ptz-map.png'
          alt='PTZ France'
          fadeIn={false} 
          loading='eager' />
      </ImageContainer>
    </ResourcesContainer>
    <HomeSectionSubtitle>
      Le PTZ a été maintenu pour les investissements immobiliers dans l’ancien jusqu’en 2021.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Attention, cela n’est pas vrai pour toutes les villes ! En effet, la loi de finances pour 2020 (promulguée le 28/12/2020) stipule que seules les zones géographiques qui n’enregistrent pas de déséquilibre entre l’offre et la demande de logements sont concernées. Ainsi, les zones B2 et C sont concernées par cette loi.
      <br />
      Outre la situation géographique du logement ancien, celui-ci doit respecter une limite énergétique. Il apparaît effectivement que seuls certains logements anciens peuvent être associés à un PTZ. Ce sont ceux dont la consommation primaire d’énergie annuelle ne dépasse pas les 331 KWh par mètre carré après travaux.
    </HomeSectionDescription>
    <ButtonContainer>
      <HomeSectionDescription>
        <strong>Vous voulez vérifier votre éligibilité,</strong>
        <br/>et le montant de votre potentiel PTZ&nbsp;?
      </HomeSectionDescription>
      <StyledButton
        link='/nous-contacter'
        title='Contactez-nous'
        background='brightOrange' />
    </ButtonContainer>
    </div>
  </PTZWithForm>
)

export default PTZResources
