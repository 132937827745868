import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import PictoMap from '../../../assets/map.inline.svg'
import MoneyPTZ from '../../molecules/PTZMoney'
import PictoMoney from '../../../assets/money.inline.svg'
import PictoFamily from '../../../assets/family.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoColContainer from '../../molecules/PictoColContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_COL = [{
  icon: PictoMoney,
  title: 'Les revenus des acquéreurs'
}, {
  icon: PictoFamily,
  title: 'Le nombre de personnes vouées à habiter dans le foyer'
}, {
  icon: PictoMap,
  title: 'La zone géographique visée par les investisseurs pour leur achat immobilier neuf'
}]

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => get(theme, 'paleGrey')};
  margin: auto;
  padding: 56px 24px;
  display: flex;
  flex-direction: column-reverse;

  ${media.greaterThan('sm')`
    padding: 72px 40px;
  `}

  ${media.greaterThan('md')`
    padding: 72px;
  `}

  ${media.greaterThan('xl')`
    flex-direction: row;
    justify-content: center;
  `}
`

const StyledCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  ${media.greaterThan('md')`
    max-width: 800px;
  `}

  ${media.greaterThan('xl')`
    width: 50%;
    max-width: 700px;
    margin: 0;
  `}

`

const LeftCol = styled(StyledCol)`
  align-items: flex-start;
  margin: auto;

  ${media.greaterThan('xl')`
    margin: 0;
  `}
`

const PTZConditions = () => (
  <Container>
    <LeftCol>
      <HomeSectionTitle tag='h2'>
        Prêt à Taux Zéro : conditions de ressources et zones géographiques
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Outre les conditions concernant le bien immobilier et le type d’investissement, il existe des critères de ressources pour le PTZ.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Dans les faits, le montant du prêt à taux zéro varie selon :
      </HomeSectionDescription>
      <PictoColContainer details={DETAILS_COL}/>
    </LeftCol>
    <StyledCol>
      <MoneyPTZ />
    </StyledCol>
  </Container>
)

export default PTZConditions
