import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;

  ${media.greaterThan('xl')`
    max-width: 520px;
    margin-right: 55%;
  `}
`

const StyledDescription = styled(Description)`
  margin: 0;
`

const PTZHero = () => (
  <>
    <StyledContainer>
      <Header max={100}>
        PTZ, comment ça marche&nbsp;?
      </Header>
      <Subtitle>
        Le Prêt à Taux Zéro est un dispositif très avantageux. Dans les faits, si vous souhaitez acheter un bien immobilier neuf, le PTZ peut vous permettre de réaliser de belles économies.
      </Subtitle>
      <StyledDescription>
        Zoom sur le Prêt à Taux Zéro, un dispositif fiscal qui permet d’investir dans l’immobilier neuf à moindre frais. PTZ, comment ça marche ? Toutes les réponses dans cet article.
      </StyledDescription>
    </StyledContainer>
  </>
)

export default PTZHero
