import React from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'

import media from '../../../utils/media'
import DropForm from '../../molecules/DropForm'

const SUBMIT = 'https://hooks.zapier.com/hooks/catch/2114713/onvil01/'

const INPUTS = [{
  type: 'text',
  name: 'firstname',
  placeholder: 'Prénom',
  initial: '',
  constraint: Yup.string().required('Votre prénom est requis')
}, {
  type: 'text',
  name: 'lastname',
  placeholder: 'Nom',
  initial: '',
  constraint: Yup.string().required('Votre nom est requis')
}, {
  type: 'select',
  name: 'taxes',
  placeholder: 'Tranche d\'imposition',
  initial: '',
  options: ['Moins de 2500€', 'Entre 2500€ et 5000€', 'Entre 5000€ et 7500€', 'Plus de 7500€'],
  constraint: Yup.string().required('Votre tranche d\'imposition est requise')
}, {
  type: 'number',
  name: 'age',
  placeholder: 'Âge',
  initial: '',
  constraint: Yup.string().required('Votre age est requis').matches(/^(1[89]|[2-9]\d)$/, 'Votre âge n\'est pas au bon format')
}, {
  type: 'text',
  name: 'contact-phone',
  placeholder: 'N° de téléphone',
  initial: '',
  constraint: Yup.string().required('Votre numéro de téléphone est requis').matches(/^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/, 'Votre téléphone n\'est pas au bon format')
}, {
  type: 'email',
  name: 'contact-email',
  placeholder: 'Email',
  initial: '',
  constraint: Yup.string().email('Votre email n\'est pas au bon format').required('Votre email est requis')
}, {
  type: 'accept',
  name: 'accept',
  placeholder: 'J’accepte d’être alerté·e des opportunités immobilières exclusives de Folhomee.',
  initial: false,
  constraint: Yup.boolean().oneOf([true])
}]

const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  padding: 0 24px;
  justify-content: center;

  ${media.lessThan('xl')`
    flex-direction: column;
    align-items: center;
  `}

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px;
  `}
`

const PartContainer = styled.div`
  max-width: 800px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const PTZWithForm = ({ children }) => (
  <Container>
    <PartContainer>
      {children}
    </PartContainer>
    <DropForm
      form='page ptz'
      submit={SUBMIT}
      inputs={INPUTS}
      subtitle='Vous doutez votre éligibilité au PTZ ? Nous pouvons vous aider.' />
  </Container>
)

export default PTZWithForm
