import React from 'react'
import styled from 'styled-components'
import { map, range, isEqual } from 'lodash'
import media from '../../../utils/media'

import StyledList from '../../atoms/StyledList'
import PTZWithForm from '../../molecules/PTZWithForm'
import ResourcesTable from '../../molecules/ResourcesTable'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ZONES_REVENUE = [{
  title: 'Paris et alentours',
  lines: ['34,8K €', '52K €', '68,17K €', '<br />12,145K €<br /><br />']
}, {
  title: 'Province',
  lines: ['30,25K €', '40,4K €', '48,58K €', '<br />8,674K €<br /><br />']
}]

const ZONES = [{
  title: 'Zone A et A Bis',
  lines: ['60K €', '84K €', '102K €', '120K €', '138K €']
}, {
  title: 'Zone B1',
  lines: ['54K €', '75,6K €', '92K €', '108K €', '124,4K €']
}, {
  title: 'Zone B2',
  lines: ['44K €', '61,6K €', '74,8K €', '88K €', '101,2K €']
}, {
  title: 'Zone C',
  lines: ['40K €', '56K €', '68K €', '80K €', '92K €']
}]

const PERSONS_REVENUE = map(range(1, 5), idx => {
  if (isEqual(idx, 1)) {
    return '1 personne'
  }

  if (isEqual(idx, 4)) {
    return 'Majoration<br />par personne<br />supplémentaire'
  }

  return `${idx} personnes`
})

const PERSONS = map(range(1, 6), idx => {
  if (isEqual(idx, 1)) {
    return '1 personne'
  }

  if (isEqual(idx, 5)) {
    return '5 personnes et plus'
  }

  return `${idx} personnes`
})

const ResourcesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  ${media.greaterThan('sm')`
    margin-top: 40px;
  `}
`

const StyledHomeSectionDescription = styled(HomeSectionDescription)`
  margin-top: 8px;
`

const StyledHomeSectionTitle = styled(HomeSectionTitle)`
  margin-top: 120px;

  ${media.greaterThan('sm')`
    margin-top: 160px;
  `}
`

const PTZResourcesConditions = () => (
  <PTZWithForm>
    <HomeSectionTitle tag='h2'>
      Concernant les conditions de ressources relatives à la TVA réduite, en voici un aperçu :
    </HomeSectionTitle>
    <ResourcesContainer>
      <ResourcesTable
        top={12}
        max={280}
        zones={ZONES_REVENUE}
        title='Nombre de personnes qui occuperont le foyer'
        persons={PERSONS_REVENUE}
        subtitle='Revenus annuels maximum <br />(en euros)'
        backTitle='Zone géographique visée'
        paddingHorizontal={5} />
    </ResourcesContainer>
    <StyledHomeSectionTitle>
      La demande de PTZ, mode d’emploi
    </StyledHomeSectionTitle>
    <HomeSectionSubtitle>
      Pour demander un PTZ pour un achat immobilier neuf, il faut se référer à une certaine logique.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Les étapes que vous devez suivre pour faire une demande de prêt à taux zéro sont :
    </HomeSectionDescription>
    <StyledList ordered={true}>
      <li>
        <strong>Vérifier votre éligibilité en vous assurant que votre situation corresponde aux critères requis</strong>, notamment en termes de revenus annuels ;
      </li>
      <li>
        <strong>Prendre connaissance du montant</strong> qui peut vous être prêté dans le cadre d’un PTZ ;
      </li>
      <li>
        <strong>Rechercher le bien immobilier neuf</strong> que vous désirez acheter ;
      </li>
      <li>
        <strong>Monter votre dossier de demande d’emprunt</strong> et le présenter à différentes banques.
      </li>
    </StyledList>
    <StyledHomeSectionDescription>
      Le tableau ci-après présente <strong>les montants prêtés au titre du PTZ selon la zone géographique</strong> que vous visez :
    </StyledHomeSectionDescription>
    <ResourcesContainer>
      <ResourcesTable
        top={12}
        zones={ZONES}
        title='Nombre de personnes qui occuperont le foyer'
        persons={PERSONS}
        subtitle='Montant maximal prêté au titre du PTZ <br />(en euros)' />
    </ResourcesContainer>
  </PTZWithForm>
)

export default PTZResourcesConditions
